import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncationPipe implements PipeTransform {
  transform(value: string, amt: number): string {
    if (!value || !value.length) {
      return;
    }
    let truncVal = value;
    if (value && value.length > amt) {
      amt = amt - 2
      truncVal = value.slice(0, amt)+'..';
    }
    return truncVal;
  }
}
