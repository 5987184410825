import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AccountingServiceInterface, AccountingServiceInterfaceWithId } from '@sk/sk-interfaces';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocalStorageService } from 'services/local-storage.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class AcctServicesService {

  private lookupSource = new BehaviorSubject<{}>({});
  serviceLookup = this.lookupSource.asObservable();
  services: AccountingServiceInterfaceWithId[];
  serviceDictionary = {};

  constructor(private firestoreService: AngularFirestore,
              private storageService: LocalStorageService) {
  }

  /**
   * Returns all accounting services for an Organization - used to populate the services
   * select
   */
  getServices(limit?: number): Observable<AccountingServiceInterfaceWithId[]> {
    const organizationId = this.storageService.getOrganizationId();
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('services', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.orderBy('name', 'asc');
        if (limit) {
          query = query.limit(limit);
        }
        return query;
      }).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as AccountingServiceInterface;
          const id = a.payload.doc.id;
          return { id, ...data } as AccountingServiceInterfaceWithId;
        })),
        map(list => {
          if (list.length !== 0) {
            this.buildServiceLookup(list);
            this.services = list;
            return list;
          }
          return null;
        })

      );
  }

  addService(acctService) {
    const organizationId = this.storageService.getOrganizationId();

    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('services').add(acctService))
      .pipe(
        map(res => {
          const id = res.id;
          const exists = true;
          const newService = { id, exists, ...acctService } as AccountingServiceInterfaceWithId;
          return newService;
        }));
  }

  deleteService(serviceId: string) {
    const organizationId = this.storageService.getOrganizationId();
    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('services').doc(serviceId).delete())
      .pipe(
        map(res => {
          const idx = this.services.findIndex(x => x.id === serviceId);
          if (idx !== -1) {
            this.services.splice(idx, 1);
          }
          if (this.serviceDictionary.hasOwnProperty(serviceId)) {
            delete this.serviceDictionary[serviceId];
          }
          this.lookupSource.next(this.serviceDictionary);
          return res;
        }));
  }

  getServiceSource() {
    return this.lookupSource
      .pipe(
        map(data => {
          if (Object.keys(data).length === 0) {
            this.getServices()
              .pipe(
                take(1)
              ).subscribe();
          }
          return data;
        })
      );
  }

  buildServiceLookup(list) {
    const lookup = {};
    list.forEach(x => {
      lookup[x.id] = x;
    });
    this.serviceDictionary = lookup;
    this.lookupSource.next(lookup);
  }

  getServiceList() {
    if (this.services) {
      return this.services.slice();
    } else {
      return null;
    }
  }

  clearData() {
    this.lookupSource.next({});
    this.services = [];
  }

}


