import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ProjectInterface, ProjectInterfaceWithId } from '@sk/sk-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocalStorageService } from 'services/local-storage.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private lookupSource = new BehaviorSubject<{}>({});
  projectLookup = this.lookupSource.asObservable();
  projects: ProjectInterfaceWithId[];
  projectDictionary = {};

  constructor(private firestoreService: AngularFirestore,
              private storageService: LocalStorageService) {}

  /**
   * Returns all projects for an Organization, used by the scheduling
   * module
  **/
  getProjects(filterValue: string = 'all', subscription: string = 'all'): Observable<ProjectInterfaceWithId[]> {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('projects', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.orderBy('name', 'asc')
        if (filterValue !== 'all') {
          query = (filterValue === 'active') ? query.where('active', '==', true) : query.where('active', '==', false)
        }
        if (subscription !== 'all') {
          query = (subscription === 'true') ? query.where('subscription', '==', true) : query.where('subscription', '==', false)
        }
        return query;
      })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as ProjectInterface;
          const id = a.payload.doc.id;
          return {id, ...data} as ProjectInterfaceWithId;
        })),
        map(list => {
          if (list.length !== 0) {
            this.buildProjectLookup(list);
            this.projects = list;
            return list;
          }
        })
      );
  }

  // This is for the project autocomplete in the invoiceItemEditDialog
  getProjectsLocation(locationId: string): Observable<ProjectInterfaceWithId[]> {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('projects', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.orderBy('name', 'asc')
        query = query.where('active', '==', true);
        query = query.where('locationId', '==', locationId);
        return query;
      })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as ProjectInterface;
          const id = a.payload.doc.id;
          return { id, ...data } as ProjectInterfaceWithId;
        }))
      );
  }

  buildProjectLookup(list) {
    const lookup = {};
    list.forEach(x => {
      lookup[x.id] = x;
    });
    this.projectDictionary = lookup;
    this.lookupSource.next(lookup);
  }

  getProjectSource() {
    return this.lookupSource
      .pipe(
        map(data => {
          if (Object.keys(data).length === 0) {
            this.getProjects()
              .pipe(
                take(1)
              ).subscribe();
          }
          return data;
        })
      );
  }

  getProjectList() {
    return this.projects.slice();
  }

  clearData() {
    this.lookupSource.next({});
    this.projects = [];
  }

  getProject(id: string): Observable<ProjectInterfaceWithId> {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('projects').doc(id).get()
      .pipe(
        map(res => {
          const project = res.data() as ProjectInterface;
          const exists = res.exists;
          const newProject = { id, exists, ...project } as ProjectInterfaceWithId;
          return newProject;
      }));
  }

  // TODO: If you haven't done a get yet then you won't be able to update the cache
  addProject(project: ProjectInterface) {
    const organizationId = this.storageService.getStorage('organizationId');
    return fromPromise(this.firestoreService.collection('organizations').doc(organizationId)
      .collection('projects').add(project))
      .pipe(
        map(res => {
              const id = res.id;
              const exists = true;
              const newProject = { id, exists, ...project } as ProjectInterfaceWithId;
              this.projectDictionary[id] = newProject;
              if (this.projects) {
                this.projects.push(newProject);
              }
              this.lookupSource.next(this.projectDictionary);
              return newProject;
            }));
  }

  updateProject(project, projectId: string) {
    const organizationId = this.storageService.getStorage('organizationId');
    const projectUpdate = Object
    delete project.id;
    delete project.exists;
    return fromPromise(
      this.firestoreService
        .collection('organizations').doc(organizationId)
        .collection('projects').doc(projectId).update(project))
        .pipe(
          map(() => {
                const id = projectId;
                const exists = true;
                const newProject = { id, exists, ...project } as ProjectInterfaceWithId;
                this.projectDictionary[id] = newProject;
                if (this.projects) {
                  const idx = this.projects.findIndex(x => x.id === projectId);
                  this.projects[idx] = newProject;
                }
                this.lookupSource.next(this.projectDictionary);
                return newProject;
              }));;
  }


}
