import { NgModule } from '@angular/core';
import {TruncationPipe} from 'shared/pipes/truncationPipe/truncationPipe';

@NgModule({
  declarations:[TruncationPipe],
  imports: [],
  exports: [TruncationPipe],
  providers: [TruncationPipe]
})
export class TruncationPipeModule {}
