import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { InvoiceItemInterfaceWithId, AccountingServiceInterfaceWithId,
         InvoiceItemInterface, TaxRateInterfaceWithId, ProjectInterfaceWithId, ProductInterfaceWithId } from '@sk/sk-interfaces';
import { FormGroup, Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, startWith, map, switchMap } from 'rxjs/operators';
import { AutoCompleteValidator } from 'shared/validators/autocomplete-validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { TaxRatesService } from 'shared/services/tax-rate.service';
import { AcctServicesService } from 'shared/services/acct-services.service';
import { ProjectService } from 'shared/services/project.service';
import * as firebase from 'firebase';
import { SuppliesService } from 'shared/services/supplies.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'sk-invoiceitem-edit-dialog',
  templateUrl: './invoiceitem-edit-dialog.component.html',
  styleUrls: ['./invoiceitem-edit-dialog.component.scss']
})
export class InvoiceItemEditDialogComponent implements OnInit, OnDestroy {
  invoiceItemId = '';
  invoiceItem: InvoiceItemInterfaceWithId;
  invoiceForm: FormGroup;
  invoiceSubtotal = 0;
  invoiceTotal = 0;
  salesTaxAmount = 0;
  invoiceType = 'Project';
  hourly = false;
  projects: ProjectInterfaceWithId[];
  projectLookup = {};
  filteredProjects: Observable<ProjectInterfaceWithId[]>;
  taxRates: Observable<TaxRateInterfaceWithId[]>;
  acctServices: AccountingServiceInterfaceWithId[];
  products: ProductInterfaceWithId[];
  editMode = false;
  onReady = false;
  defaultValue = null;

   constructor(private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private taxRatesService: TaxRatesService,
              private acctServicesService: AcctServicesService,
              private suppliesService: SuppliesService,
              private projectService: ProjectService,
              private dialogRef: MatDialogRef<InvoiceItemEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: InvoiceItemInterfaceWithId) {
  }

  ngOnInit() {
    this.getData();
    this.editMode = (this.data.id) ? true : false;
    this.invoiceItem = this.data;
    this.taxRates = this.taxRatesService.getTaxRates();

    this.initForm();
    if (this.editMode) {
      this.patchForm();
    } else {
      this.newItemControls();
    }
    // I dont think I will need to do this this.calculateAmounts();
  }

  compareTax(o1, o2): boolean {
    return o1.description === o2.description;
  }

  compareService(o1, o2): boolean {
    return o1.name === o2.name;
  }

  compareProduct(o1, o2): boolean {
    return o1.productName === o2.productName;
  }

  newItemControls() {
    // If this is a brand new invoice item, you need to let the user enter the project,
    // Need to get the project, invoiceType, hourly/flat fee, billing date,
    this.invoiceForm.addControl('project', new FormControl(null, [Validators.required, AutoCompleteValidator.bind(this)]));
    this.filterProjects();
    this.invoiceForm.addControl('invoiceType', new FormControl('Project'));
    this.invoiceForm.addControl('invoiceDate', new FormControl(moment(), [Validators.required]));
    this.invoiceForm.addControl('service', new FormControl(null, Validators.required));
    this.onInvoiceTypeChanges();
  }

  patchForm() {
    this.salesTaxAmount = this.invoiceItem.invoiceTaxAmount;
    this.invoiceSubtotal = this.invoiceItem.invoiceSubtotal;
    this.invoiceTotal = this.invoiceItem.invoiceTotal;
    this.invoiceType = this.invoiceItem.invoiceType;
    if (this.invoiceItem.invoiceType === 'Order') {
      this.hourly = (this.invoiceItem.hourlyRate !== null) ? true : false;
    }
    if (this.hourly) {
      this.invoiceForm.removeControl('invoiceSubtotal');
      this.invoiceForm.addControl('hourQuantity', new FormControl(this.invoiceItem.hourQuantity, Validators.required));
      this.invoiceForm.addControl('hourlyAmount', new FormControl(this.invoiceItem.hourlyRate.toFixed(2), Validators.required));
    }
    this.invoiceForm.patchValue({
      salesTaxRate: this.invoiceItem.salesTaxRate,
      invoiceSubtotal: this.invoiceItem.invoiceSubtotal.toFixed(2)
    })
    let service;
    let product;
    if (this.invoiceType === 'Material') {
      product = this.products.find(x => x.id === this.invoiceItem.productId);
      this.invoiceForm.addControl('product', new FormControl(product, Validators.required));
    } else {
      service = this.acctServices.find(x => x.id === this.invoiceItem.serviceId);
      this.invoiceForm.addControl('service', new FormControl(service, Validators.required));
    }
  }

  notZeroValidator(control: AbstractControl) {
    // Ensure that if the user is supposed to enter a value that it is not
    // less than 0
    if (control.value) {
      if (parseFloat(control.value) <= 0) {
        return { required: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  initForm() {
    this.invoiceForm = this.fb.group({
      salesTaxRate: [this.invoiceItem.salesTaxRate, Validators.required],
      invoiceSubtotal: ['0.00', [Validators.required, this.notZeroValidator.bind(this)]]
    });
    this.onValueChanges();
  }

  onValueChanges() {
    this.invoiceForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.calculateAmounts();
      });
  }

  onInvoiceTypeChanges() {
  this.invoiceForm.get('invoiceType').valueChanges
    .subscribe(val => {
      this.invoiceType = val;
      if (this.invoiceType === 'Material') {
        this.invoiceForm.removeControl('service');
        this.invoiceForm.addControl('product', new FormControl(null, Validators.required));
      } else {
        this.invoiceForm.removeControl('product');
        this.invoiceForm.addControl('service', new FormControl(null, Validators.required));
      }
    });
}

  calculateAmounts() {
    // If the user changes a field, recalculate everything and patch the form
    if (this.invoiceForm.value.salesTaxRate !== null) {
      this.invoiceSubtotal =
        (!this.hourly) ?
          parseFloat(this.invoiceForm.value.invoiceSubtotal) :
          parseFloat(this.invoiceForm.value.hourQuantity) * parseFloat(this.invoiceForm.value.hourlyAmount);
      this.salesTaxAmount = this.invoiceSubtotal * (this.invoiceForm.value.salesTaxRate.rate / 100);
      this.invoiceTotal = this.invoiceSubtotal + (this.invoiceSubtotal * (this.invoiceForm.value.salesTaxRate.rate / 100));
      if (this.hourly) {
        this.invoiceForm.patchValue({
          invoiceSubtotal: this.invoiceSubtotal.toFixed(2)
        })
      }
    }
  }

  onSubmit() {
    if (this.invoiceForm.invalid || (this.invoiceTotal <= 0)) {
      this.snackBar.open('Please fill required fields', 'Error', {
        duration: 5000
      });
      return
    }

    if (this.editMode) {
      const invoiceItem: InvoiceItemInterfaceWithId = {
        locationId: this.invoiceItem.locationId,
        projectId: this.invoiceItem.projectId,
        orderId: this.invoiceItem.orderId,
        invoiceType: this.invoiceItem.invoiceType,
        hourQuantity: (this.hourly) ? parseFloat(this.invoiceForm.value.hourQuantity) : null,
        hourlyRate: (this.hourly) ? parseFloat(this.invoiceForm.value.hourlyAmount) : null,
        salesTaxRate: this.invoiceForm.value.salesTaxRate,
        serviceId: (this.invoiceType !== 'Material') ? this.invoiceForm.value.service.id : null,
        productId: (this.invoiceType === 'Material') ? this.invoiceForm.value.product.id : null,
        invoiceTaxAmount: this.salesTaxAmount,
        invoiceTotal: this.invoiceTotal,
        invoiceSubtotal: this.invoiceSubtotal,
        billingStartDate: this.invoiceItem.billingStartDate,
        billingEndDate: this.invoiceItem.billingEndDate,
        invoiceId: this.invoiceItem.invoiceId,
        status: this.invoiceItem.status,
        id: this.invoiceItem.id,
        exists: this.invoiceItem.exists
      }
      this.dialogRef.close(invoiceItem);
    } else {
      const invoiceItem: InvoiceItemInterface = {
        locationId: this.invoiceItem.locationId,
        projectId: this.invoiceForm.value.project.id,
        orderId: null,
        invoiceType: this.invoiceForm.value.invoiceType,
        hourQuantity: null,
        hourlyRate: null,
        salesTaxRate: this.invoiceForm.value.salesTaxRate,
        serviceId: (this.invoiceType !== 'Material') ? this.invoiceForm.value.service.id : null,
        productId: (this.invoiceType === 'Material') ? this.invoiceForm.value.product.id : null,
        invoiceTaxAmount: this.salesTaxAmount,
        invoiceTotal: this.invoiceTotal,
        invoiceSubtotal: this.invoiceSubtotal,
        billingStartDate: firebase.firestore.Timestamp.fromDate(this.invoiceForm.value.invoiceDate.toDate()),
        billingEndDate: firebase.firestore.Timestamp.fromDate(this.invoiceForm.value.invoiceDate.toDate()),
        invoiceId: this.invoiceItem.invoiceId,
        status: 'active'
      }
      this.dialogRef.close(invoiceItem);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  filterProjects() {
    this.filteredProjects = this.invoiceForm.get('project').valueChanges
      .pipe(
        startWith<string | ProjectInterfaceWithId>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filteredProjects(name) : this.projects.slice())
      );
  }

  _filteredProjects(value: string): ProjectInterfaceWithId[] {
    const filterValue = value.toLowerCase();
    return this.projects.filter(project => project.name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayProject(project: ProjectInterfaceWithId): string | undefined {
    return project ? project.name : undefined;
  }

  // Get the lists of services and products for the selects
  getData() {
    this.acctServicesService.getServiceSource()
      .pipe(
        switchMap(data => {
          if (data && (Object.keys(data).length !== 0)) {
            this.acctServices = this.acctServicesService.getServiceList();
          }
          return this.suppliesService.getProductSource()
        }),
        switchMap(productData => {
          if (productData && (Object.keys(productData).length !== 0)) {
            this.products = this.suppliesService.getProductList();
          }
          return this.projectService.getProjectSource()
        }))
      .subscribe(projectData => {
        if (projectData && (Object.keys(projectData).length !== 0)) {
          this.projectLookup = projectData;
          this.projects = this.projectService.getProjectList();
          this.onReady = true;
        }
      },
        error => {
          if (!environment.production) {
            console.log('error getting staff and locations' + error);
          }
        });
  }

  ngOnDestroy(): void {
  }
}
