import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverlayModule} from '@angular/cdk/overlay';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import {UiLoaderService} from './ui-loader.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OverlayModule,
    MatProgressSpinnerModule,
  ],
  providers: [UiLoaderService],
  entryComponents: [MatSpinner]
})
export class UiLoaderModule {
}
