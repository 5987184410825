import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceDisplayComponent } from './invoice-display.component';
import { CustomMaterialModule } from '@sk/custom-material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TruncationPipeModule } from 'shared/pipes/truncationPipe/truncationPipe.module';
import { AlertDialogModule } from 'shared/dialogs/alert-dialog/alert-dialog.module';
import { InvoiceDetailModule } from '../../_office/invoicing/invoicing-overview/invoicing-dashboard/invoice-detail/invoice-detail.module';
import { InvoiceDisplayRoutingModule } from './invoice-display-routing.module';

@NgModule({
  declarations: [ InvoiceDisplayComponent ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    FlexLayoutModule,
    InvoiceDisplayRoutingModule,
    InvoiceDetailModule,
    AlertDialogModule,
    TruncationPipeModule,
  ],
  providers: [],
  exports: [ InvoiceDisplayComponent ]
})
export class InvoiceDisplayModule {
}
