import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ProductInterface, ProductInterfaceWithId } from '@sk/sk-interfaces';
import { fromPromise } from 'rxjs/internal-compatibility';
import { LocalStorageService } from 'services/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class SuppliesService {

  private lookupSource = new BehaviorSubject<{}>({});
  productLookup = this.lookupSource.asObservable();
  products: ProductInterfaceWithId[] = [];
  productDictionary = {};

  constructor(private afStore: AngularFirestore,
              private storageService: LocalStorageService) {
  }


  getOrganizationProducts(): Observable<ProductInterfaceWithId[] | null> {
    const organizationId = this.storageService.getOrganizationId();
    return this.afStore.collection('organizations').doc(organizationId)
      .collection('products').snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as ProductInterface;
          const id = a.payload.doc.id;
          const exists = a.payload.doc.exists;
          const product = { id, exists, ...data } as ProductInterfaceWithId;
          this.productDictionary[id] = product;
          return product;
        })),
        map(list => {
          if (list.length !== 0) {
            this.products = list;
            this.lookupSource.next(this.productDictionary);
            return list;
          }
          return null;
        })
      );
  }

  getProductSource() {
    return this.lookupSource
      .pipe(
        map(data => {
          if (Object.keys(data).length === 0) {
            this.getOrganizationProducts()
              .pipe(
                take(1)
              ).subscribe();
          }
          return data;
        })
      );
  }

  getProductList() {
    if (this.products) {
      return this.products.slice();
    } else {
      return null;
    }
  }

  clearData() {
    this.lookupSource.next({});
    this.products = [];
  }

  addOrganizationProduct(product: ProductInterface) {
    const organizationId = this.storageService.getOrganizationId();
    return fromPromise(this.afStore.collection('organizations').doc(organizationId)
      .collection('products').add(product))
      .pipe(
        map(res => {
          const id = res.id;
          const exists = true;
          const newProduct = { id, exists, ...product } as ProductInterfaceWithId;
          this.productDictionary[id] = newProduct;
          this.products.push(newProduct);
          this.lookupSource.next(this.productDictionary);
          return newProduct;
        }));
  }


  updateOrganizationProduct(product: ProductInterface, productId: string) {
    const organizationId = this.storageService.getOrganizationId();
    return fromPromise(this.afStore.collection('organizations').doc(organizationId)
      .collection('products').doc(productId).update(product))
      .pipe(
        map(res => {
          const id = productId;
          const exists = true;
          const newProduct = { id, exists, ...product } as ProductInterfaceWithId;
          this.productDictionary[id] = newProduct;
          const idx = this.products.findIndex(x => x.id === productId);
          if (idx !== -1) {
            this.products[idx] = newProduct;
          }
          this.lookupSource.next(this.productDictionary);
          return newProduct;
        }));
  }

  deleteOrganizationProduct(productId: string) {
    const organizationId = this.storageService.getOrganizationId();
    return fromPromise(this.afStore.collection('organizations').doc(organizationId)
      .collection('products').doc(productId).delete())
      .pipe(
        map(res => {
          const idx = this.products.findIndex(x => x.id === productId);
          if (idx !== -1) {
            this.products.splice(idx, 1);
          }
          if (this.productDictionary.hasOwnProperty(productId)) {
            delete this.productDictionary[productId];
          }
          this.lookupSource.next(this.productDictionary);
          return res;
        }));
  }

}

