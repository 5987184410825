import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TaxRateInterface, TaxRateInterfaceWithId } from '@sk/sk-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'services/local-storage.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class TaxRatesService {

  constructor(private firestoreService: AngularFirestore,
              private storageService: LocalStorageService) {
  }

  /**
   * Returns all accounting services for an Organization - used to populate the services
   * select
   */
  getTaxRates(limit?: number): Observable<TaxRateInterfaceWithId[]> {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('taxrates', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.orderBy('description', 'asc');
        if (limit) {
          query = query.limit(limit);
        }
        return query;
      }).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as TaxRateInterface;
          const exists = a.payload.doc.exists;
          const id = a.payload.doc.id;
          return {id, exists, ...data} as TaxRateInterfaceWithId;
        }))
      );
  }

  // Get a single order by id
  getTaxRate(id: string): Observable<TaxRateInterfaceWithId> {
    const organizationId = this.storageService.getOrganizationId();
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('taxrates').doc(id).get()
      .pipe(
        map(res => {
          const taxRate = res.data() as TaxRateInterfaceWithId;
          taxRate.exists = res.exists;
          taxRate.id = res.id
          return taxRate;
        }));
  }

  addTaxRate(taxRate) {
    const organizationId = this.storageService.getStorage('organizationId');
    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('taxrates').add(taxRate));
  }

  deleteTaxRate(taxRateId: string) {
    const organizationId = this.storageService.getStorage('organizationId');
    return fromPromise(this.firestoreService
        .collection('organizations').doc(organizationId)
        .collection('taxrates').doc(taxRateId).delete());
  }

}
