import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvoiceInterfaceWithId } from '@sk/sk-interfaces';
import { Subject } from 'rxjs';
import { LocationService } from 'shared/services/location.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { InvoiceService } from 'shared/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sk-invoice-display',
  templateUrl: './invoice-display.component.html',
  styleUrls: ['./invoice-display.component.scss']
})
export class InvoiceDisplayComponent implements OnInit, OnDestroy {

  onReady = false;
  gotData = false;
  locationLookup = {};
  invoiceId = '';
  organizationId = '';
  invoice: InvoiceInterfaceWithId;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              private locationService: LocationService,
              private invoiceService: InvoiceService) {
  }

  ngOnInit() {
    this.getData();
    this.route.queryParams
      .subscribe(params => {
        console.log('params ' + JSON.stringify(params));
        this.invoiceId = params.invoiceId;
        this.organizationId = params.organizationId;
        console.log('invoice id is ' + this.invoiceId);
        console.log('org id is ' + this.organizationId);
        this.getInvoice();
      });
  }

  getInvoice() {
    this.invoiceService.getInvoiceWithOrganizationId(this.invoiceId, this.organizationId)
      .subscribe(invoice => {
        this.invoice = invoice;
        console.log('got invoice : ' + JSON.stringify(this.invoice))
        if (this.invoice) {
          this.onReady = true;
        } else {
          this.snackBar
            .open('Could not find invoice', 'Error', {
              duration: 3000
            });
        }
      },
        error => {
          if (!environment.production) {
            console.error(error);
          }
        })
  }

  getData() {
    console.log('hi from getStaffAndLocations');
    this.locationService.getLocationSource()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(locationData => {
        if (locationData && (Object.keys(locationData).length !== 0)) {
          this.locationLookup = locationData;
          this.gotData = true;
        }
      },
        error => {
          if (!environment.production) {
            console.error(error);
          }
        });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
