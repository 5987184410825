import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { InvoiceInterface, InvoiceInterfaceWithId } from '@sk/sk-interfaces';
import { Observable, forkJoin } from 'rxjs';
import { map, concatAll, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'services/local-storage.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient,
              private storageService: LocalStorageService,
              private firestoreService: AngularFirestore) {
  }

  getInvoices(status: string = 'all'): Observable<InvoiceInterfaceWithId[]> {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('invoices', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.orderBy('dueDate', 'desc');
        if (status !== 'all') {
          query = query.where('status', '==', status);
        }
        return query;
      }).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as InvoiceInterface;
          const id = a.payload.doc.id;
          return { id, ...data } as InvoiceInterfaceWithId;
        }))
      );
  }

  getInvoice(id: string) {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('invoices').doc(id).get()
      .pipe(
        map(res => {
          const invoice = res.data() as InvoiceInterfaceWithId;
          invoice.exists = res.exists;
          invoice.id = id;
          return invoice;
        }));
  }

  getInvoiceWithOrganizationId(id: string, organizationId: string) {
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('invoices').doc(id).get()
      .pipe(
        map(res => {
          if (res.exists) {
            const invoice = res.data() as InvoiceInterfaceWithId;
            invoice.exists = res.exists;
            invoice.id = id;
            return invoice;
          } else {
            return null;
          }
        }));
  }

  addInvoice(invoice) {
    const organizationId = this.storageService.getStorage('organizationId');
    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('invoices').add(invoice)).pipe(
      map(res => {
        const id = res.id;
        const exists = true;
        const newInvoice = { id, exists, ...invoice } as InvoiceInterfaceWithId;
        return newInvoice;
      }));
  }

  addInvoices(invoices: InvoiceInterface[]) {
    const organizationId = this.storageService.getStorage('organizationId');

    const observables = invoices.map(invoice => {
      return fromPromise(this.firestoreService
        .collection('organizations')
        .doc(organizationId)
        .collection('invoices').add(invoice)).pipe(
        map(res => {
          const id = res.id;
          const exists = true;
          const newInvoice = { id, exists, ...invoice } as InvoiceInterfaceWithId;
          return newInvoice;
        }));
    });

    return forkJoin(observables).pipe(concatAll());
  }

  updateInvoice(invoice, invoiceId: string) {
    const organizationId = this.storageService.getStorage('organizationId');
    delete invoice.id;
    delete invoice.exists;
    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('invoices').doc(invoiceId).update(invoice));
  }

  deleteInvoice(invoice: string) {
    const organizationId = this.storageService.getStorage('organizationId');
    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('invoices').doc(invoice).delete());
  }

  reSendInvoice(invoiceId: string) {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.http
      .post<string>(environment.api_url + '/resendInvoice', JSON.stringify({ organizationId, invoiceId }))
      .pipe(
        take(1),
        map(res => {
          if (res === 'success') {
            return res;
          } else {
            if (!environment.production) {
              console.log('Error resending invoice', res);
            }
            return res;
          }
        })
      );
  }


}
