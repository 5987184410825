import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LocationInterface, LocationInterfaceWithId } from '@sk/sk-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocalStorageService } from 'services/local-storage.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private lookupSource = new BehaviorSubject<{}>({});
  locationLookup = this.lookupSource.asObservable();
  locations: LocationInterfaceWithId[];
  locationDictionary = {};

  constructor(private firestoreService: AngularFirestore,
              private storageService: LocalStorageService) {
  }

  /**
   * Returns all locations for an Organization - used to populate the locations
   * select
   */
  // TODO: Remove the second map and build the list in the first one
  getLocations(): Observable<LocationInterfaceWithId[]> {
    const organizationId = this.storageService.getOrganizationId();
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('locations').snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as LocationInterface;
          const id = a.payload.doc.id;
          const exists = a.payload.doc.exists;
          return { id, exists, ...data } as LocationInterfaceWithId;
        })),
        map(list => {
          if (list.length > 0) {
            this.buildLocationLookup(list);
            this.locations = list;
            return list;
          }
          return null;
        })
      );
  }

  getLocationSource() {
    return this.lookupSource
      .pipe(
        map(data => {
          if (Object.keys(data).length === 0) {
            this.getLocations()
              .pipe(
                take(1)
              ).subscribe();
          }
          return data;
        })
      );
  }

  buildLocationLookup(list) {
    const lookup = {};
    list.forEach(x => {
      lookup[x.id] = x;
    });
    this.locationDictionary = lookup;
    this.lookupSource.next(lookup);
  }

  getLocationList() {
    if (this.locations) {
      return this.locations.slice();
    } else {
      return null;
    }
  }

  clearData() {
    this.lookupSource.next({});
    this.locations = [];
  }

  getLocation(locationId): Observable<LocationInterfaceWithId> {
    const organizationId = this.storageService.getOrganizationId();
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('locations').doc(locationId).snapshotChanges()
      .pipe(
        map(a => {
          const data: any = a.payload.data() as LocationInterface;
          const id = a.payload.id;
          const exists = a.payload.exists;
          return { id, exists, ...data } as LocationInterfaceWithId;
        })
      );
  }

  addLocation(location) {
    const organizationId = this.storageService.getOrganizationId();
    return fromPromise(this.firestoreService.collection('organizations').doc(organizationId)
      .collection('locations').add(location))
      .pipe(
        map(res => {
          const id = res.id;
          const exists = true;
          const newLocation = { id, exists, ...location } as LocationInterfaceWithId;
          this.locationDictionary[id] = newLocation;
          this.locations.push(newLocation);
          this.lookupSource.next(this.locationDictionary);
          return newLocation;
        }));
  }

  updateLocation(locationData, id: string) {
    const organizationId = this.storageService.getOrganizationId();
    this.clearData();
    delete locationData.id;
    delete locationData.exists;
    return fromPromise(
      this.firestoreService
        .collection('organizations').doc(organizationId)
        .collection('locations').doc(id).update(locationData))
      .pipe(
        map(() => {
          const exists = true;
          const location = { id, exists, ...locationData } as LocationInterfaceWithId;
          const idx = this.locations.findIndex(x => x.id === id);
          this.locations[idx] = location;
          this.locationDictionary[id] = location;
          this.lookupSource.next(this.locationDictionary);
          return location;
        }));
  }


}
