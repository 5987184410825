import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './_shared/guards/auth.guard';
import { CustomMaterialModule } from '@sk/custom-material';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { registerLocaleData } from '@angular/common';

import localeEs from '@angular/common/locales/es-US';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import { InvitationComponent } from './components/invitation/invitation.component';
import { SignOutGuard } from 'shared/guards/sign-out.guard';
import { UiSpinnerModule } from 'shared/ui-services/ui-spinner/ui-spinner.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { QueryParamsGuard } from 'shared/guards/overview-guard.service';
import { InvoiceDisplayModule } from './components/invoice-display/invoice-display.module';
import { httpInterceptorProviders } from 'shared/http-interceptors';
import { UiLoaderModule } from 'shared/ui-services/ui-loader/ui-loader.module';

// registerLocaleData(environment.locale);
console.log('env.language ' + environment.language);
registerLocaleData(environment.locale, environment.language);
moment.locale(environment.language);




@NgModule({
  declarations: [
    AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything

    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    // AngularFireMessagingModule, // imports firebase/messaging only needed for messaging features
    AppRoutingModule,
    CustomMaterialModule,
    HttpClientModule,
    UiSpinnerModule,
    UiLoaderModule,
    InvoiceDisplayModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    AuthGuard,
    SignOutGuard,
    QueryParamsGuard,
    httpInterceptorProviders,
    { provide: LOCALE_ID, useValue: environment.language },
    { provide: FirestoreSettingsToken, useValue: {} },
    { provide: MAT_DATE_LOCALE, useValue: environment.language }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
