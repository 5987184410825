import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './_shared/guards/auth.guard';
import {SignOutGuard} from './_shared/guards/sign-out.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'no-access',
    loadChildren: () => import('./components/no-access/no-access.module').then(m => m.NoAccessModule)
  },
  {
    path: 'signout',
    canActivate: [SignOutGuard],
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'office',
    canActivate: [AuthGuard],
    loadChildren: () => import('./_office/office.module').then(m => m.OfficeModule)
  },
  {
    path: 'invitation',
    loadChildren: () => import('./components/invitation/invitation.module').then(m => m.InvitationModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('./components/invoice-display/invoice-display.module').then(m => m.InvoiceDisplayModule)
  },
  {path: '', redirectTo: '/office/dashboard', pathMatch: 'full'},
  {path: '**', redirectTo: '/office/dashboard', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
