import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverlayModule} from '@angular/cdk/overlay';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import {UiSpinnerService} from './ui-spinner.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OverlayModule,
    MatProgressSpinnerModule,
  ],
  providers: [UiSpinnerService],
  entryComponents: [MatSpinner]
})
export class UiSpinnerModule {
}
