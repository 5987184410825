import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { InvoiceItemInterface, InvoiceItemInterfaceWithId } from '@sk/sk-interfaces';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'services/local-storage.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class InvoiceItemService {

  constructor(private firestoreService: AngularFirestore,
              private storageService: LocalStorageService) {
  }

  getInvoiceItems(invoiceId: string): Observable<InvoiceItemInterfaceWithId[]> {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('invoice-items', ref => ref
        .where('invoiceId', '==', invoiceId)).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as InvoiceItemInterface;
          const id = a.payload.doc.id;
          const exists = a.payload.doc.exists;
          return { id, exists, ...data } as InvoiceItemInterfaceWithId;
        }))
      );
  }

  getInvoiceItemsByOrderId(orderId: string): Observable<InvoiceItemInterfaceWithId[]> {
    const organizationId = this.storageService.getStorage('organizationId');
    return this.firestoreService.collection('organizations').doc(organizationId)
      .collection('invoice-items', ref => ref
        .where('orderId', '==', orderId)).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as InvoiceItemInterface;
          const id = a.payload.doc.id;
          const exists = a.payload.doc.exists;
          return { id, exists, ...data } as InvoiceItemInterfaceWithId;
        }))
      );
  }


  addInvoiceItem(invoiceItem) {
    const organizationId = this.storageService.getStorage('organizationId');
    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('invoice-items').add(invoiceItem)).pipe(
      map(res => {
        const id = res.id;
        const exists = true;
        const newInvoiceItem = { id, exists, ...invoiceItem } as InvoiceItemInterfaceWithId;
        return newInvoiceItem;
      }));
  }

  updateInvoiceItem(invoiceItem, invoiceItemId: string) {
    const organizationId = this.storageService.getStorage('organizationId');
    delete invoiceItem.id;
    delete invoiceItem.exists;
    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('invoice-items').doc(invoiceItemId).update(invoiceItem));
  }

  deleteInvoiceItem(invoiceItemId: string) {
    const organizationId = this.storageService.getStorage('organizationId');
    return fromPromise(this.firestoreService
      .collection('organizations').doc(organizationId)
      .collection('invoice-items').doc(invoiceItemId).delete());
  }


}
