import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(){}

  getStorage(item: string) {
    return localStorage.getItem(item);
  }

  setStorage(item: string, value: string) {
    localStorage.setItem(item, value);
  }

  clearStorage() {
    localStorage.clear();
  }


  getOrganizationId(){
    return localStorage.getItem('organizationId');
  }

}
