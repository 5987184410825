import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceDetailComponent } from './invoice-detail.component';
import { InvoiceItemEditDialogModule } from './invoiceitem-edit-dialog/invoiceitem-edit-dialog.module';
import { CustomMaterialModule } from '@sk/custom-material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { TruncationPipeModule } from 'shared/pipes/truncationPipe/truncationPipe.module';

@NgModule({
  declarations: [ InvoiceDetailComponent ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    NgxMaskModule.forRoot(),
    FlexLayoutModule,
    InvoiceItemEditDialogModule,
    RouterModule,
    TruncationPipeModule,
  ],
  providers: [],
  exports: [ InvoiceDetailComponent ]
})
export class InvoiceDetailModule {
}
