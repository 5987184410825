import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { LocalStorageService } from 'shared/services/local-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AngularFireAuth,
              private snackBar: MatSnackBar,
              private storageService: LocalStorageService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.authService.user
      .pipe(
        map(user => {
          if (!user) {
            this.router.navigateByUrl('login');
            return false;
          } else {
            const userRole = this.storageService.getStorage('role');
            // ToDo: Remove this before release
            if(!userRole){
              this.snackBar
                .open('Access Denied', 'Roll not available yet... ', {
                  duration: 3000,
                });
            }
            if ((userRole === 'technician') || (userRole === 'contractor')) {
              this.router.navigateByUrl('no-access')
              return false;
            }
            if ((userRole === 'contact')) {
              // ToDo: This need to route to client section
              this.router.navigateByUrl('client')
              return false;
            }
            if ((userRole !== 'admin') && (userRole !== 'manager')) {
              //ToDo: translate this snackbar message: might have to do manually since no html file here.
              this.snackBar
              .open('Access Denied', 'You must be an admin to login to this app', {
                duration: 3000,
              });
              this.router.navigateByUrl('login');
              return false;
            }
          }
          return true;
        })
      );
  }

}
