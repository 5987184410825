import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sk-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'dashboard',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/baseline_dashboard_navy_blue.svg'));

  }

  ngOnInit() {

  }


}
