import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceItemEditDialogComponent } from './invoiceitem-edit-dialog.component';
import {CustomMaterialModule} from '@sk/custom-material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [ InvoiceItemEditDialogComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(),
    RouterModule
  ],
  providers: [],
  entryComponents: [InvoiceItemEditDialogComponent],
  exports: [ InvoiceItemEditDialogComponent ]
})
export class InvoiceItemEditDialogModule {
}
