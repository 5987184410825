// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import localeEn from '@angular/common/locales/en';
import { EnvironmentInterface } from '@sk/sk-interfaces';

export const environment : EnvironmentInterface = {
  production: false,
  language: 'en-US',
  scheduleCheck: false,
  region: 'us',
  locale: localeEn,
  firebase: {
    apiKey: 'AIzaSyASqbYrwNmF8cJqonY6wSA82pQ6J-JE6WA',
    authDomain: 'servicekicker-dev.firebaseapp.com',
    databaseURL: 'https://servicekicker-dev.firebaseio.com',
    projectId: 'servicekicker-dev',
    storageBucket: 'servicekicker-dev.appspot.com',
    messagingSenderId: '296370750205'
  },
  api_url: 'https://us-central1-servicekicker-dev.cloudfunctions.net',
  base_url: 'https://dev.servicekicker.com',
  release: 'dev 0.0.28'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
