import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'services/local-storage.service';

@Injectable()
export class SignOutGuard implements CanActivate {


  constructor(private authService: AngularFireAuth,
              private storageService: LocalStorageService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.user
      .pipe(
        map(user => {
          if (!user) {
            this.router.navigateByUrl('login')
              .then(() => window.location.reload());
            return false;
          }
          if (user) {
            this.authService.auth.signOut()
              .then(() => {
                localStorage.clear();
                this.storageService.clearStorage();
                return this.router.navigateByUrl('login');
              })
              .then(() => {
                  window.location.reload();
                  return false;
                }
              );

          }
        })
      );
  }

}
