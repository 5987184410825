import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { InvoiceItemInterfaceWithId } from '@sk/sk-interfaces';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'environments/environment';
import { InvoiceItemService } from 'shared/services/invoice-item.service';
import { ProjectService } from 'shared/services/project.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AlertDialogComponent } from 'shared/dialogs/alert-dialog/alert-dialog.component';
import { InvoiceItemEditDialogComponent } from './invoiceitem-edit-dialog/invoiceitem-edit-dialog.component';
import { AcctServicesService } from 'shared/services/acct-services.service';
import { SuppliesService } from 'shared/services/supplies.service';

@Component({
  selector: 'sk-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit, OnDestroy {
  @Input() invoiceId: string;
  @Input() allowEdit: boolean;
  @Output() invoiceAmountChanged = new EventEmitter;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  onReady = false;
  gotInvoiceDetail = false;
  projectLookup = {};
  serviceLookup = {};
  productLookup = {};
  dataSource: MatTableDataSource<InvoiceItemInterfaceWithId> = new MatTableDataSource([]);
  displayedColumns = ['project', 'item', 'subtotal', 'tax', 'total', 'edit', 'delete'];
  unsubscribe$: Subject<void> = new Subject<void>();
  filterValue = 'true';
  snackbarDeleted = 'Invoice Item has been deleted.';
  snackbarSuccess = 'Success';
  archiveInvoice = 'Archive Invoice';
  cancelTooltip = 'Return to invoice dashboard';
  archiveMsg = 'Are you sure you want to delete this invoice item?';
  yesButton = 'yes';
  noButton = 'no';

  constructor(public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private projectService: ProjectService,
              private acctServicesService: AcctServicesService,
              private suppliesService: SuppliesService,
              private invoiceItemService: InvoiceItemService) {
  }

  ngOnInit() {
    if (!this.allowEdit) {
      this.displayedColumns = ['project', 'item', 'subtotal', 'tax', 'total'];
    }
    this.getData();
    this.getInvoiceDetail();
  }

  onEditItem(invoiceItem: InvoiceItemInterfaceWithId) {
    // Allow user to change the invoice item in a dialog
    // If they change the total of the invoice item, emit an event
    // to the parent component
    const currentTotal = invoiceItem.invoiceTotal;
    const dialogRef = this.dialog.open(InvoiceItemEditDialogComponent, {
      panelClass: 'invoiceitem-edit-dialog',
      data: invoiceItem
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const newInvoiceItem:InvoiceItemInterfaceWithId = result;
        if (newInvoiceItem.invoiceTotal !== currentTotal) {
          // If the invoiceitem total changes, adjust the invoice total
          this.invoiceAmountChanged.emit(newInvoiceItem.invoiceTotal - currentTotal);
        }
        this.invoiceItemService.updateInvoiceItem(newInvoiceItem, newInvoiceItem.id)
          .subscribe(() => {
          }, error => {
              if (!environment.production) {
                console.error(error);
              }
            })

      }

    })
  }

  onDeleteItem(invoiceItem: InvoiceItemInterfaceWithId) {
    // Show the user a modal to confirm that they want to delete the
    // invoice item, if they do, send an event to the parent component
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        header: this.archiveInvoice,
        cancelTooltip: this.cancelTooltip,
        message: this.archiveMsg,
        buttons: [this.yesButton, this.noButton]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes' || result === 'ja') {
        this.invoiceAmountChanged.emit(invoiceItem.invoiceTotal * -1);
        this.invoiceItemService.deleteInvoiceItem(invoiceItem.id)
          .subscribe(() => {
            this.snackBar.open(this.snackbarDeleted, this.snackbarSuccess, {
              duration: 5000
            });
          },
            error => {
              if (!environment.production) {
                console.error(error);
              }
            })
      }
    })
  }

  getInvoiceDetail() {
    this.invoiceItemService.getInvoiceItems(this.invoiceId)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(list => {
        if ((list) && (list.length !== 0)) {
          this.dataSource = new MatTableDataSource(list);
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }, 200);
          this.gotInvoiceDetail = true;
        } else {
          this.gotInvoiceDetail = false;
        }
      },
        error => {
          if (!environment.production) {
            console.error(error);
          }
        })
  }

  getData() {
    this.projectService.getProjectSource()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(projectData => {
        if (projectData && (Object.keys(projectData).length !== 0)) {
          this.projectLookup = projectData;
        }
        return this.suppliesService.getProductSource();
      }),
      switchMap(productData => {
        if (productData && (Object.keys(productData).length !== 0)) {
          this.productLookup = productData;
        }
        return this.acctServicesService.getServiceSource()
      }))
      .subscribe(serviceData => {
        if (serviceData && (Object.keys(serviceData).length !== 0)) {
          this.serviceLookup = serviceData;
        }
        this.onReady = true;
      },
      error => {
        if (!environment.production) {
          console.error(error);
        }
      });

  }

  translate(invoiceDel:string, success:string, archiveInvoice:string, returnDashboard:string,
  shouldDelete:string, yesText:string, noText:string) {
    this.snackbarDeleted = invoiceDel;
    this.snackbarSuccess = success;
    this.archiveInvoice = archiveInvoice;
    this.cancelTooltip = returnDashboard;
    this.archiveMsg = shouldDelete;
    this.yesButton = yesText;
    this.noButton = noText;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
